import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './Menu.css'

const Menu = () => {
    let [menu, setMenu] = useState()

    useEffect(() => {
        axios.get('/pages').then(pages => {
            return pages
        }).then(result => {
            setMenu(result.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])
    
    return (
        <>
            <nav className="navbar navbar-light bg-light mb-3 menu">
                <ul className="nav mr-auto">
                    <li className="nav-item">
                        <Link className="nav-link" to="/">Home</Link>
                    </li>
                    { menu !== undefined ? menu.map((nav, index) => {
                        return <li className="nav-item" key={ index }>
                            <Link className="nav-link" to={ nav.slug }>{ nav.title }</Link>
                        </li>
                    }) : null }
                </ul>
            </nav>
        </>
    )
}

export default Menu