import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './components/Home/home'
import Contact from './components/Contact/contact'
import About from './components/About/about'
import Sidebar from './components/Layouts/Sidebar'
import Menu from './components/Layouts/Menu/Menu'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'
axios.defaults.baseURL = "https://panel.mmaghsoudi.ir/api/v1"

function App() {
  return (
    <Router>
      <div className="container main">
        <div className="row">
            <Sidebar />
          <div className="col-md-9 content">
            <Menu />
            <Switch>
              <Route exact path="/contact-us">
                <Contact />
              </Route>
              <Route exact path="/about-us">
                <About />
              </Route>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/services">
                <Home />
              </Route>
          </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
