import React, { useEffect, useState } from 'react'
import axios from 'axios'

const Sidebar = () => {
    let [data, setData] = useState()
    let [avatar, setAvatar] = useState()

    useEffect(() => {
      axios.get('/info').then(response => {
        return response
      }).then(results => {
        setData(results.data)
      }).catch(err => {
        console.log(err)
      })

      // get avatar
      getAvatar()
    }, [])

    const getAvatar = () => {
      axios.get('/info/avatar').then(res => {
        return res
      }).then(result => {
        setAvatar(result.data)
      }).catch(err => {
        console.log(err)
      })
    }

    return (
        <div className="col-md-3 sidebar">
            <div className="card">
              <div className="card-body">
                  <img className="img-thumbnail img-fluid rounded-circle" alt="میثم مقصودی" src={avatar !== undefined ? avatar.value : null} />
                  <br />
                <div>
                  <ul className="nav">
                    { data !== undefined ? data.map((info, index) => {
                      return <li className="nav-item" key={index}><strong>{ info.key }:</strong> { info.value }</li>
                    }) : null }
                  </ul>
                </div>
              </div>
            </div>
        </div>
    )
}

export default Sidebar