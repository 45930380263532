import React, { useEffect, useState } from 'react'
import axios from 'axios'
import PreLoad from '../Utils/preLoad'
import ReactHtmlParser from 'react-html-parser';
import ReCAPTCHA from 'react-google-recaptcha'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const Contact = (props) => {
    const [page, setPage] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [description, setDescription] = useState("")
    const [phone, setPhone] = useState("")
    const [file, setFile] = useState(null)
    const [btnEnable, setBtnEnable] = useState(true)
    const [captchaChecked, setCaptchaCehecked] = useState(false)

    useEffect(() => {
        axios.post('/page/slug', {slug: 'contact-us'})
            .then(result => {
                return result
            }).then(response => {
                setPage(response.data[0].body)
            }).catch(err => {
                console.log(err)
            })
    }, [])

    const onFileUpload = (e) => {
        e.preventDefault();
        // Get the file Id
        let id = e.target.id;
        // Create an instance of FileReader API
        let file_reader = new FileReader();
        // Get the actual file itself
        let files = e.target.files[0];
        file_reader.onload = () => {
          // After uploading the file
          // appending the file to our state array
          // set the object keys and values accordingly
          setFile([file, { file_id: id, uploaded_file: file_reader.result }]);
        };
        // reading the actual uploaded file
        file_reader.readAsDataURL(files);
    }

    const handleContact = (e) => {
        e.preventDefault();

        if(captchaChecked) {
            sendContact()
        }else {
            toast.success("لطفا تیک من ربات نیستم را بزنید", {autoClose:3000})
        }
    }

    const sendContact = () => {
        axios.post('/contact', {
            name: name,
            email: email,
            description: description,
            phone: phone,
            file: file
        }).then(response => {
            setName("")
            setEmail("")
            setDescription("")
            setPhone("")
            setFile("")
            toast.success(response.data.message, {autoClose:3000})
        }).catch(err => {
            toast.success(err.config.message, {autoClose:3000})
        })
    }

    const onChange = (value) => {
        console.log("Captcha value:", value);
        const RECAPTCHA_SERVER_KEY = "6LcN-E4aAAAAALJH6sVC6C59u0QkWO7mrWyGz281"
        const humanKey = value

        // Validate Human
        const isHuman = axios.post(`/check-recaptcha`, {
            secret: `${RECAPTCHA_SERVER_KEY}`,
            response: `${humanKey}`
        })
        .then(res => res)
        .then(json => {
            setCaptchaCehecked(true)
            return json.data
        })
        .catch(err => {
            throw new Error(`Error in Google Siteverify API. ${err.message}`)
        })

        if (humanKey === null || !isHuman) {
            throw new Error(`YOU ARE NOT A HUMAN.`)
        }
    }

    const ChangeHandler = (e) => {
        console.log(phone.length)
        if(name.length > 1 && email.length > 1 && description.length > 1 && phone.length > 1) {
            setBtnEnable(false)
        }else {
            setBtnEnable(true)
        }
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h2 className="text-right">
                        تماس با ما
                    </h2>
                    <div className="description">
                        { ReactHtmlParser(page) }

                        { page ? 
                            <form onChange={ChangeHandler} onSubmit={e => handleContact(e)} action="/contact" method="post" encType="multipart/form-data">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="نام خود را وارد کنید ..." name="name" id="name" value={name} onChange={e => setName(e.target.value) } />
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder="ایمیل خود را وارد کنید ..." name="email" id="email" value={email} onChange={e => setEmail(e.target.value) } />
                                </div>
                                <div className="form-group">
                                    <textarea rows="3" cols="4" name="description" id="description" className="form-control" placeholder="متن خود را وارد کنید ..." value={description} onChange={ e => setDescription(e.target.value) }></textarea>
                                </div>
                                <div className="form-group">
                                    <input type="number" className="form-control" placeholder="شماره همراه خود را وارد کنید ..." name="phone" id="phone" value={phone} onChange={ e => setPhone(e.target.value) } />
                                </div>
                                <div className="form-group">
                                    <input type="file" className="form-control" name="file" id="file" title="فایل ضمیمه را انتخاب کنید" onChange={onFileUpload} placeholder="فایل ضمیمه را انتخاب کنید ..." />
                                </div>
                                <div className="form-group">
                                    <ReCAPTCHA
                                        sitekey="6LcN-E4aAAAAAPamPCNQeIQ6n79J0AeZaF5BOihc"
                                        onChange={onChange}
                                    />
                                </div>
                                <button type="submit" disabled={btnEnable} className="btn btn-danger">ارسال</button>
                            </form>
                        : <PreLoad /> }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact