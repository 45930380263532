import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser';
import PreLoad from '../Utils/preLoad'
import './about.css'

const About = () => {
    const [page, setPage] = useState()

    useEffect(() => {
        axios.post('/page/slug', {slug: 'about-us'})
            .then(result => {
                return result
            }).then(response => {
                setPage(response.data[0].body)
            }).catch(err => {
                console.log(err)
            })
    }, [])
    
    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h2 className="text-right">
                        درباره ما
                    </h2>
                    <div className="description">
                        { page !== undefined ? ReactHtmlParser(page) 
                            : <PreLoad />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default About