
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import PreLoad from '../Utils/preLoad'
import './home.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

const Home = () => {
    const [service, setService] = useState()

    useEffect(() => {
        axios.get('/services')
            .then(result => {
                setService(result.data)
            }).catch(err => {
                toast.success(err, {autoClose:3000})
            })
    }, [])

    return (
        <>
            <div className="card services">
                <div className="card-body">
                    <h2 className="text-right">خدمات</h2>
                    
                    <div className="row">
                        { service ? 
                            service.map((item, index) => {
                                return <div className="col" key={index}>
                                    <div className="col-service">
                                        <img src={item.image} className="rounded-sm rounded-circle" alt={item.title} />
                                        <div className="card-body">
                                            <p className="card-text">
                                                {item.title}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            })
                        : <PreLoad /> }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home